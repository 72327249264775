import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import Tabs from '@shared/ui/navigation/Tabs';
import { BalanceGraphPeriod } from '@pages/Overview/OverallBalance/constants';
import { OverallBalanceCurve } from '@entities/current-user';
import { ContainerColumn } from '@components/styled';
import { getDefaultGraphRange, mapBalanceGraphData } from '@pages/Overview/OverallBalance/utils';
import { BALANCE_GRAPH_DATA } from '@pages/Overview/OverallBalance/mocks';
import { WithSkeleton } from '@shared/ui/progress-loaders/Skeleton';
const Container = styled(ContainerColumn)(props => ({
    height: 'auto',
    gap: props.theme.spacing_sizes.l,
    alignItems: 'end',
}));
const BalanceGraph = ({ isLoading = false, }) => {
    const [period, setPeriod] = useState(BalanceGraphPeriod['30D']);
    const { 
    // data,
    defaultRange, } = useMemo(() => {
        switch (period) {
            case BalanceGraphPeriod['30D']:
                return {
                    data: mapBalanceGraphData(BALANCE_GRAPH_DATA.slice(0, 30)),
                    defaultRange: getDefaultGraphRange(BalanceGraphPeriod['30D']),
                };
            case BalanceGraphPeriod['90D']:
                return {
                    data: mapBalanceGraphData(BALANCE_GRAPH_DATA.slice(0, 90)),
                    defaultRange: getDefaultGraphRange(BalanceGraphPeriod['90D']),
                };
            case BalanceGraphPeriod['180D']:
                return {
                    data: mapBalanceGraphData(BALANCE_GRAPH_DATA.slice(0, 180)),
                    defaultRange: getDefaultGraphRange(BalanceGraphPeriod['180D']),
                };
            case BalanceGraphPeriod['1Year']:
            default:
                return {
                    data: mapBalanceGraphData(BALANCE_GRAPH_DATA),
                    defaultRange: getDefaultGraphRange(BalanceGraphPeriod['1Year']),
                };
        }
    }, [period]);
    const handlePeriodChange = (activeTab) => {
        setPeriod(activeTab);
    };
    return (_jsxs(Container, { children: [_jsx(WithSkeleton, { isLoading: isLoading, width: 182, height: 40, children: _jsx(Tabs, { variant: 'chart-tabs', tabs: [
                        { value: BalanceGraphPeriod['1Year'], label: '1 Year' },
                        { value: BalanceGraphPeriod['180D'], label: '180 D' },
                        { value: BalanceGraphPeriod['90D'], label: '90 D' },
                        { value: BalanceGraphPeriod['30D'], label: '30 D' },
                    ], onChange: handlePeriodChange, activeTab: period }) }), _jsx(OverallBalanceCurve, { data: [], defaultRange: defaultRange, isLoading: isLoading })] }));
};
export default BalanceGraph;
